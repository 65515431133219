/* istanbul ignore file */
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {Alignment} from '../../productPage/constants';

type CustomCssVarsFnParams = Omit<Parameters<CustomCssVarsFn>[0], 'tpaData'>;

const enum FlexAlignment {
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
  Center = 'center',
}

export const customCssVars = ({styleParams, isRTL}: CustomCssVarsFnParams) => {
  const getErrorMessageAlignment = () => {
    switch (styleParams.strings.productPage_productDetailsAlignment?.value || Alignment.LEFT) {
      case Alignment.LEFT:
        return isRTL ? FlexAlignment.FlexEnd : FlexAlignment.FlexStart;
      case Alignment.RIGHT:
        return isRTL ? FlexAlignment.FlexStart : FlexAlignment.FlexEnd;
      case Alignment.CENTER:
        return FlexAlignment.Center;
    }
  };

  return {
    errorMessageFlexAlignment: getErrorMessageAlignment(),
    imageGalleryPaginationDotsDirection: isRTL ? 'row-reverse' : 'row',
  };
};
